<template>
    <div class="global-content">
        <filtrate type="status" />
        <div class="inner">
             <div v-if="!emptyData">
                <van-list v-model="loading" :finished="finished" finished-text="没有更多了" :immediate-check="false" @load="onLoad">
                    <div class="part" v-for="(item,index) in dataList" :key="index">
                        <div :class="item.devStatus == 'online' ? 'head' :'head head-off'">
                            <div>名称：{{item.devName}}</div>
                            <div>编号：{{item.devCode}}</div>
                        </div>
                        <div class="block">
                            <div class="flex">
                                <div :class="item.devStatus == 'online' ? 'online' :'offline'">状态：{{item.devStatus == 'online' ? '在线' :'离线'}}</div>
                            </div>
                            <div class="flex">位置：{{item.devRoad || "暂未设置位置"}}</div>
                            <div class="flex">项目：{{ projectName || "暂无"}}</div>
                            <div class="time">更新时间：{{item.lastUpdateTime}}</div>
                        </div>
                    </div>
                </van-list>
             </div>
            <div v-else><van-empty description="没有更多数据了" /></div>
        </div>
    </div>
</template>
<script>
import filtrate from '@/components/itgs/filtrate.vue'
export default {
    name:'Warn',
    components: {
        filtrate
    },
    data(){
        return{
            emptyData:false,
            loading:false,
            finished:false,
            pageNum:1,
            pageSize:10,
            filter:{
                devCode:'',
                start:'',
                end:'',
            },
            dataList:[],
            projectCode:null,
            projectName:null
        }
    },
    mounted(){
        this.projectCode = window.sessionStorage.getItem('projectCode');
        this.projectName = window.sessionStorage.getItem('projectName');
        this.onLoad();
    },
    methods:{
        reload(param){
            this.filter.devCode = param.devCode;
            this.filter.start = param.start;
            this.filter.end = param.end;
            this.finished = false;
            this.dataList = [];
            this.pageNum = 1; //筛选时间  重置页码
            this.onLoad();
        },
        onLoad(){
            const postData = {
                // beginTime :this.filter.start,
                // endTime :this.filter.end,
                devCode:this.filter.devCode,
                projectCode:this.projectCode
            };
            this.$api.ITGS.getDevInfoPage(postData).then((data)=>{
                this.dataList = data;
                // if(data.length < this.pageSize){
                    this.finished  = true;
                // }
                // this.dataList = this.dataList.concat(data);
                // if(this.dataList.length ==0){
                //     this.emptyData = true
                // }else{
                //     this.emptyData = false;
                // }
                // this.loading = false;
                // this.pageNum ++;
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.van-empty{margin: 25% 0;}
.inner{
    .part{margin-top: 2%;}
    .head{
        display: flex;
        justify-content: space-between;
        font-size: .8rem;
        padding: 2% 4%;
        background-image: linear-gradient(141deg,#0474ff 0%,#59a8f9 51%,#54a7fd 75%);
        color: #fff;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        font-weight: bold;
    }
    .head-off{
        background-image: linear-gradient(141deg, #505050 0%, #929292 51%, #a0a0a0 75%);
    }
    .block{
        padding: 0 4% 4% 4%;
        background-color: #fff;
        font-size: .8rem;
        border: 1px solid #ececec;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        .flex{
            display: flex;
            justify-content:space-between;
            margin: 2% 0;
            font-weight: bold;
            .online{color: #0474ff;}
            .offline{color: #656565;}
        }
        .time{color: #656565;margin-top:2%;}
    }
}
</style>