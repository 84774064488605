<template>
  <div>
     <van-collapse v-model="activeNames" accordion>
        <van-collapse-item title="筛选条件" name="1">
            <van-cell title="按设备编号" :value="filter.devName" is-link @click="devPicker=true"/>
            <van-cell v-if="type == 'warn'" title="按报警类型" :value="filter.typeName" is-link @click="typeShow=true"/>
            <van-field v-if="type !== 'status'" v-model="filter.showDate" label="按时间段查" placeholder="点击选择时间" right-icon="close" input-align="right" @click-right-icon.stop="cleanTime" readonly @click="timeShow=true"/>
            <van-button type="info" class="btn-filter" @click="filterData">筛选</van-button>
        </van-collapse-item>
    </van-collapse>
    <van-popup v-model="devPicker" round position="bottom">
        <van-search v-model="filterKeyword" placeholder="请输入搜索关键词" @input="filterDev" />
        <van-picker show-toolbar :columns="devData" @cancel="devCancel" @confirm="devConfirm"/>
    </van-popup>
    <van-action-sheet v-model="typeShow" :actions="warnType" @select="typeSelect" />
    <!-- 气象数据/过车统计数据过多，设置仅选择两天 -->
    <van-calendar v-if="date" ref="calendar" :default-date="defaultDate" v-model="timeShow" color='#0474ff' type="range" :min-date="minDate" :max-date="maxDate" :max-range="2"  @confirm="timeConfirm" />
    <van-calendar v-else ref="calendar" :default-date="defaultDate" v-model="timeShow" color='#0474ff' type="range" :min-date="minDate" :max-date="maxDate"  @confirm="timeConfirm" />
  </div>
</template>

<script>
export default {
    name: 'tabBar',
    data(){
        return{
            activeNames:'',
            filterKeyword:'',
            devPicker:false,
            typeShow:false,
            timeShow:false,
            typeActions:[],
            devData:[],
            devDataNon:[],
            minDate: new Date(2018, 0, 1),
            maxDate: new Date(),
            filter:{
                devCode:'',
                devName:'',
                start:'',
                end:'',
                showDate:'',
                typeId:3,
                typeName:'设备故障告警',
            },
            warnType:[
                {id:1,name:'设备缺电告警'},{id:2,name:'节目单丢失告警'},{id:3,name:'设备故障告警'},
                // {id:4,name:'积水结冰告警'},
                {id:5,name:'倾角告警'},
                {id:6,name:'设备掉电告警'},{id:7,name:'电子围栏告警'},{id:8,name:'现场求助告警'},{id:9,name:'应急车道停车告警'}
            ],
            defaultDate:[]
        }
    },
    mounted(){
        const projectCode = window.sessionStorage.getItem('projectCode');
        this.$api.ITGS.getAllDevInfo({
            projectCode:projectCode
        }).then((data) => {
            data.forEach(element => {
                element.text = element.devName;
            });
            this.devData = data;
            this.devDataNon = data;
        });
        // 默认时间
        if(this.date?.length){
            this.filter.start = this.date?.[0];
            this.filter.end = this.date?.[1];
            this.filter.showDate =  this.filter.start +'至'+this.filter.end;
            this.defaultDate = [
                new Date(this.filter.start),
                new Date(this.filter.end)
            ]
        }
    },
    methods:{
        // 返回的日期 格式化
        formatDate(date) {
            return `${date.getFullYear()}-${date.getMonth() > 8 ? date.getMonth() + 1 :'0'+(date.getMonth()+1) }-${date.getDate() > 9 ? date.getDate() : '0'+date.getDate()}`;
        },
        // 清除时间选择器
        cleanTime(){
            this.filter.showDate = '';
            this.filter.start = '';
            this.filter.end = '';
            this.$refs.calendar.reset();
        },
        // 筛选单个设备 ---- 过滤
        filterDev(k){
          this.devData = this.devDataNon.filter( e => e.text.toLowerCase().includes(k.toLowerCase()))
        },
        // 筛选单个设备 ---- 确认
        devConfirm(e){
            this.filter.devName = e.text;
            this.filter.devCode = e.devCode;
            this.filterKeyword = '';
            this.devData = this.devDataNon;
            this.devPicker = false;
        },
        // 筛选单个设备 ---- 取消
        devCancel(){
            this.filterKeyword = '';
            this.filter.devName = '';
            this.filter.devCode = '';
            this.devPicker = false;
        },
        // 选择报警类型
        typeSelect(e){
            this.filter.typeName = e.name;
            this.filter.typeId = e.id;
            this.typeShow = false;
        },
        // 时间确认区间
        timeConfirm(date){
            this.timeShow = false;
            this.filter.start = this.formatDate(date[0]);
            this.filter.end = this.formatDate(date[1]);
            this.filter.showDate =  this.filter.start +'至'+this.filter.end;
        },
        // 筛选数据
        filterData(){
            this.activeNames = '';
            // this.$parent.onLoad(this.filter);
            this.$parent.reload(this.filter);
        }
    },
    props: {
      type: String,
      date:Array
    }
}
</script>

<style>
.btn-filter{width: 25%;height: 2rem;margin: 4% auto;display: block;}
</style>